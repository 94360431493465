import { FlowType } from '/~/composables/checkout/checkout-types'
import billPaymentsRoutes from '/~/templates/bill-payments/routes'

const templateRoutes = {
  '/payments': {
    path: '/payments',
    name: 'payments',
    component: () =>
      import('/~/templates/bill-payments/views/payments/payments.vue'),
    redirect: { name: 'payments-make' },
    meta: {
      flowType: FlowType.payment,
    },
    children: [
      {
        path: 'make',
        name: 'payments-make',
        component: () => import('./views/payments/make/payments-make.vue'),
        meta: {
          title: 'Make a payment',
          flowType: FlowType.payment,
          toolbar: false,
          isBillPaymentsV15Route: true,
        },
        params: true,
      },
      {
        path: 'make/bank-files/:orderId',
        name: 'payments-make-batch',
        component: () => import('./views/payments/make/payments-make.vue'),
        meta: {
          title: 'Make a payment',
          flowType: FlowType.batch,
          toolbar: false,
          isBillPaymentsV15Route: true,
        },
        params: true,
      },
      {
        path: 'payee',
        name: 'payments-make',
        component: () =>
          import(
            '/~/templates/bill-payments/views/payments/make/payments-make.vue'
          ),
        redirect: { name: 'payments-select-payee' },
        meta: {
          flowType: FlowType.payment,
        },
        children: [
          {
            path: 'new-payee/:type?',
            name: 'new-payee',
            meta: {
              title: 'Add Payee',
              flowType: FlowType.payment,
              back: { name: 'payments-make' },
            },
            component: () =>
              import(
                '/~/templates/bill-payments/views/payments/payees/components/new-payee.vue'
              ),
          },
          {
            path: ':type?',
            name: 'payments-select-payee',
            component: () =>
              import(
                '/~/templates/bill-payments/views/payments/make/components/payees/payees-select.vue'
              ),
            meta: {
              title: 'Select Payees',
              flowType: FlowType.payment,
            },
          },
        ].filter(Boolean),
      },
      {
        path: 'payees',
        component: () =>
          import(
            '/~/templates/bill-payments/views/payments/payees/payments-main.vue'
          ),
        redirect: { name: 'payments-payees' },
        children: [
          {
            path: '',
            name: 'payments-payees',
            component: () =>
              import(
                '/~/templates/bill-payments/views/payments/payees/payments-payees.vue'
              ),
            meta: {
              title: 'Payees',
            },
          },
          {
            path: 'new-payee/:type?',
            name: 'payments-new-payee',
            meta: {
              title: 'Add payee details',
              back: { name: 'payments-payees' },
            },
            component: () =>
              import(
                '/~/templates/bill-payments/views/payments/payees/payments-new-payee.vue'
              ),
          },
        ],
      },
      {
        path: 'complete-payid/:orderId',
        name: 'payments-complete-payid',
        props: true,
        component: () =>
          import(
            '/~/views/checkout.v2/views/checkout/views/checkout-complete-payid.vue'
          ),
        meta: {
          title: 'Complete payment with PayID',
          toolbar: false,
        },
      },
      {
        path: 'confirmation/:orderId',
        name: 'payments-confirmation',
        component: () =>
          import(
            './views/payments/make/views/successful/payments-make-successful-wrapper.vue'
          ),
        meta: {
          title: 'Checkout Confirmation',
          toolbar: false,
          flowType: FlowType.payment,
          isBillPaymentsV15Route: true,
        },
      },
      {
        path: 'confirmation/statement/:orderId',
        name: 'payments-confirmation-statement',
        component: () =>
          import(
            './views/payments/make/views/successful/payments-make-successful-wrapper.vue'
          ),
        meta: {
          title: 'Checkout Confirmation',
          toolbar: false,
          flowType: FlowType.statement,
          isBillPaymentsV15Route: true,
        },
      },
      {
        path: 'confirmation/bank-files/:orderId',
        name: 'payments-confirmation-batch',
        component: () =>
          import(
            './views/payments/make/views/successful/payments-make-successful-wrapper.vue'
          ),
        meta: {
          title: 'Checkout Confirmation',
          toolbar: false,
          flowType: FlowType.batch,
          isBillPaymentsV15Route: true,
        },
      },
    ],
  },
  '/activity/:id': {
    path: '/activity/:id',
    name: 'activity-details',
    props: true,
    meta: {
      hideMobileHeader: true,
      toolbar: false,
      isBillPaymentsV15Route: true,
    },
    component: () => import('./views/activity/activity-details-wrapper.vue'),
  },
}

const routes = billPaymentsRoutes.map(
  (route) => templateRoutes[route.path] ?? route
)

export default routes
